/* index.css */

/* Container for the whole component */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Button styles */
  button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Image container */
  .image-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
  }
  
  /* Individual image card */
  .image-card {
    position: relative;
  }
  
  /* Checkbox */
  input[type="checkbox"] {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  /* Image */
  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  